@import "./Variables.scss";

.App {
  width: 100%;
  height: 100vh;
  background-color: $color-primary;
  user-select: none;
  &.dark {
    background-color: $color-primary-dark;
  }
}

.wrapper {
  background-color: $color-primary;
  color: #4d4d4d;
  text-align: center;
  display: grid;
  position: relative;
  &.dark {
    background-color: $color-primary-dark;
    color: $secondary-font-color-dark
  }
  .ui-container {
    display: inline-flex;
    position: relative;
    width: $ui-width;
    height: scale(660);
    grid-row: 2;
    margin: auto;
    text-align: center;
    background-color: $color-secondary;
    border-radius: scale(15);
    box-shadow: 0 0px scale(15) 0 #a1a1a1;
    .navbar-container {
      position: relative;
      float: top;
      width: 100%;
      height: scale(50);
      .tab {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        height: scale(30);
        width: scale(200);
        margin: scale(10) scale(10) 0 scale(15);
        background-color: $color-tertiary;
        border-top-left-radius: scale(15);
        border-top-right-radius: scale(15);
        font-size: 1.15rem;
        font-weight: lighter;
        line-height: scale(25);
        &.shadow-tab {
          z-index: 0;
          box-shadow: 0 scale(0) scale(15) 0 #a1a1a1;
          background-color: transparent;
          &.dark {
            box-shadow: 0 scale(0) scale(15) 0 #161616;
          }
        }
        &.dark {
          background-color: $color-tertiary-dark;
        }
      }
      .active-tab {
        z-index: 2;
        &.shadow-tab {
          z-index: 1;
        }
      }
      .editor-tab {
        left: scale(230);
      }
      .export-tab {
        left: scale(460);
      }
    }
   
    &.dark {
      background-color: $color-secondary-dark;
      box-shadow: 0 0 scale(15) 0 black;
    }
  }
  
}

.logo-container {
  grid-row: 1;
  width: scale(1300);
  height: scale(65);
  margin: auto;
  background-repeat: no-repeat;
  background-size: 25%;
  background-image: url('./img/logotype.png');
  margin-top: scale(12);
  &.dark {
    background-image: url('./img/logotype-dark.png');
  }
}

.hidden {
  display: none;
}

.feature-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

